<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const topHeader = ref<HTMLElement | null>(null)
const tabContainer = ref<HTMLElement | null>(null)
const slider = ref<HTMLElement | null>(null)
const { height } = useElementSize(topHeader)
const { currentWebsite } = useWebsite()
const { tabs } = await useLegalArea('legal-area-pages')
const error = useError()
const { legalSection, getCanonicalUrl } = useRouteHelper()

const { data: items } = await useMarketingStrip(
  `${currentWebsite.value}-marketing-strips`
)
const { topHeaderHeight: topHeaderHeightState } = useHeaderAndNavbarHeight()
const { menuStatus } = useMenu()

const { isDialogOpen: isDialogOpenMiniCart } = useDialog(
  SIDEBAR_IDS.headerSideCart
)
const { isDialogOpen: isDialogOpenLogin } = useDialog(SIDEBAR_IDS.loginSidebar)

const route = useRoute()
const router = useRouter().currentRoute

const currentPagePath = ref<string>('')

const messages = computed(() => items.value?.content.messages)

const pageLabel = computed(() => {
  const currentPath = router.value.path
  const activeTab = tabs.value?.find(tab =>
    currentPath.endsWith(getCanonicalUrl(tab.url?.target?.target ?? ''))
  )
  return activeTab?.name ?? ''
})

const updateTabSliderPosition = (index: number) => {
  if (!tabContainer.value || !slider.value) return

  const tabElements = tabContainer.value.querySelectorAll('.shortcut-element')
  if (!tabElements[index]) return

  const tab = tabElements[index] as HTMLElement
  const container = tabContainer.value
  const sliderElement = slider.value

  // Update slider position and width
  sliderElement.style.transform = `translateX(${tab.offsetLeft}px)`
  sliderElement.style.width = `${tab.offsetWidth}px`

  // Calculate scroll position
  const scrollLeft = container.scrollLeft
  const containerWidth = container.offsetWidth
  const tabLeft = tab.offsetLeft
  const tabWidth = tab.offsetWidth

  // Scroll into view if needed
  if (tabLeft < scrollLeft) {
    container.scrollTo({
      left: tabLeft,
      behavior: 'smooth',
    })
  } else if (tabLeft + tabWidth > scrollLeft + containerWidth) {
    container.scrollTo({
      left: tabLeft + tabWidth - containerWidth,
      behavior: 'smooth',
    })
  }
}

const handleTabClick = (index: number) => {
  updateTabSliderPosition(index)
}

watch(
  route,
  () => {
    currentPagePath.value = router.value.path
  },
  { immediate: true }
)

watch(
  height,
  (val, oldVal) => {
    if (val !== oldVal) {
      topHeaderHeightState.value = val
    }
  },
  { immediate: true }
)

watch(
  () => router.value.path,
  () => {
    const activeIndex = tabs.value?.findIndex(tab =>
      router.value.path.endsWith(getCanonicalUrl(tab.url?.target?.target ?? ''))
    )
    if (activeIndex !== -1) {
      nextTick(() => {
        updateTabSliderPosition(activeIndex)
      })
    }
  },
  { immediate: true }
)

onMounted(() => {
  const activeIndex = tabs.value?.findIndex(tab =>
    router.value.path.endsWith(getCanonicalUrl(tab.url?.target?.target ?? ''))
  )
  if (activeIndex !== -1) {
    nextTick(() => {
      updateTabSliderPosition(activeIndex)
    })
  }
})
</script>

<template>
  <div class="theme-global relative">
    <div ref="topHeader" class="top-header z-40 print:hidden">
      <HeaderStripWrapper
        :is-hidden="
          menuStatus?.isActive || isDialogOpenMiniCart || isDialogOpenLogin
        "
      >
        <MarketingStrip :messages="messages" />
      </HeaderStripWrapper>
    </div>

    <HeaderWrapperLogic>
      <template #header="props">
        <HeaderDefault
          v-bind="{
            ...props,
            currentBrand: 'global',
            deferredCurrentBrand: 'global',
            staticOnMobile: true,
          }"
        />
      </template>
      <template #navigation="props">
        <HeaderNavigationBar v-bind="props" />
      </template>
    </HeaderWrapperLogic>

    <div class="h-full w-full md:mb-0">
      <div
        class="grid-standard bg-primitives-off-white py-lg md:py-xl lg:py-4xl print:py-0"
      >
        <main id="main" class="col-span-12 md:col-span-10 md:col-start-2">
          <div
            class="gap-md md:gap-xxl bg-primitives-off-white m-auto flex flex-col"
          >
            <div
              v-if="!error?.statusCode"
              class="gap-md md:gap-xxl flex flex-col"
            >
              <h1 class="text-link-4 md:text-link-3">
                {{ $ts('legalArea.area') }}
                <span v-if="pageLabel" class="text-book-4 md:text-book-3">
                  / {{ pageLabel }}
                </span>
              </h1>

              <div v-if="tabs?.length" class="relative">
                <div
                  ref="tabContainer"
                  class="gap-xxs md:gap-xs slider-tab scrollbar-hide scroll-smoot flex touch-pan-x overflow-x-auto"
                >
                  <div class="gap-xxs md:gap-xs flex">
                    <AtomsShortcut
                      v-for="(shortcut, index) in tabs"
                      :key="shortcut.name"
                      :link="legalSection(shortcut.url?.target?.target ?? '')"
                      size="M"
                      class="shortcut-element w-40 flex-shrink-0 md:w-40"
                      bg-color="white"
                      :underline="
                        shortcut.url?.target?.target
                          ? router.path.endsWith(
                              getCanonicalUrl(shortcut.url?.target?.target)
                            )
                          : false
                      "
                      :shortcut-index="index"
                      @link-click="handleTabClick(index)"
                    >
                      <template #text>
                        <span>
                          {{ shortcut.name }}
                        </span>
                      </template>
                    </AtomsShortcut>
                  </div>
                  <div
                    ref="slider"
                    class="slider-tab-border bg-neutral-black absolute bottom-0 hidden h-0.5 transition-all duration-300 md:block"
                  />
                </div>
              </div>
            </div>
            <slot />
          </div>
        </main>
      </div>
    </div>

    <div class="mb-[var(--navbar-height)] lg:mb-0 print:hidden">
      <FooterMain />
    </div>
    <GlobalSlides />
  </div>
</template>
